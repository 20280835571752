import { clsx } from 'clsx/lite'

const barColorClasses: Record<ProgressVariant, string> = {
  default: clsx('bg-primary-600 dark:bg-primary-600'),
  blue: clsx('bg-primary-600 dark:bg-primary-600'),
  dark: clsx('bg-gray-600 dark:bg-gray-300'),
  green: clsx('bg-green-600 dark:bg-green-500'),
  red: clsx('bg-red-600 dark:bg-red-500'),
  yellow: clsx('bg-yellow-400'),
  indigo: clsx('bg-indigo-600 dark:bg-indigo-500'),
  purple: clsx('bg-purple-600 dark:bg-purple-500'),
}

const outsideTextColorClasses: Record<ProgressVariant, string> = {
  default: '',
  blue: clsx('text-primary-700 dark:text-primary-500'),
  dark: clsx('dark:text-white'),
  green: clsx('text-green-700 dark:text-green-500'),
  red: clsx('text-red-700 dark:text-red-500'),
  yellow: clsx('text-yellow-700 dark:text-yellow-500'),
  indigo: clsx('text-indigo-700 dark:text-indigo-500'),
  purple: clsx('text-purple-700 dark:text-purple-500'),
}

const outsideTextSizeClasses: Record<ProgressLabelSize, string> = {
  sm: 'text-sm',
  md: 'text-base',
}

const progressSizeClasses: Record<ProgressSize, string> = {
  sm: clsx('h-1.5 text-xs leading-none'),
  md: clsx('h-2.5 text-xs leading-none'),
  lg: clsx('h-4 text-sm leading-none'),
  xl: clsx('h-6 text-base leading-tight'),
}

export interface UseProgressClassesProps {
  color: Ref<ProgressVariant>
  size: Ref<ProgressSize>
  labelPosition: Ref<ProgressLabelPosition>
  labelSize: Ref<ProgressLabelSize>
}

export function useProgressClasses(props: UseProgressClassesProps): {
  innerClasses: Ref<string>
  outerClasses: Ref<string>
  outsideLabelClasses: Ref<string>
} {
  const innerClasses = computed(() => clsx(barColorClasses[props.color.value], progressSizeClasses[props.size.value]))
  const outerClasses = computed(() => clsx(progressSizeClasses[props.size.value]))
  const outsideLabelClasses = computed(() =>
    clsx(outsideTextColorClasses[props.color.value], outsideTextSizeClasses[props.labelSize.value]),
  )

  return {
    innerClasses,
    outerClasses,
    outsideLabelClasses,
  }
}
