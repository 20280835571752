<template>
  <div>
    <template v-if="label || (labelProgress && labelPosition === 'outside')">
      <div class="mb-1 flex justify-between">
        <div class="flex items-center gap-3">
          <span
            class="font-medium"
            :class="outsideLabelClasses"
          >{{ label }}</span>
          <slot />
        </div>
        <span
          v-if="labelProgress && labelPosition === 'outside'"
          class="text-sm font-medium"
          :class="outsideLabelClasses"
        >{{ progress }}%</span>
      </div>
    </template>
    <div
      class="w-full rounded-full bg-gray-200 dark:bg-gray-700"
      :class="outerClasses"
    >
      <div
        class="rounded-full p-0.5 text-center font-medium text-primary-100"
        :class="innerClasses"
        :style="{ width: progress + '%' }"
      >
        <template v-if="labelProgress && labelPosition === 'inside'">
          {{ progress }}%
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useProgressClasses } from '#imports'

interface Props {
  color?: ProgressVariant
  label?: string
  labelPosition?: ProgressLabelPosition
  labelProgress?: boolean
  progress: number
  size?: ProgressSize
  labelSize?: ProgressLabelSize
}

const props = withDefaults(defineProps<Props>(), {
  color: 'purple',
  label: '',
  labelPosition: 'none',
  progress: 0,
  size: 'md',
  labelSize: 'md',
})

const { innerClasses, outerClasses, outsideLabelClasses } = useProgressClasses(toRefs(props))
</script>
